import React from "react";
import { formatDate, isValidDate } from "@ufginsurance/ui-kit";
import { stripCurrencyChars, sortCoverages } from "./util";
import structuredClone from "@ungap/structured-clone";
import cn from "classnames";
import CoverageTitle from "./CoverageTitle";
import ScheduleItemTable from "./scheduleItems/ScheduleItemTable";
import {
  coverageTermOverrides,
  coveragePanelJsx
} from "./coveragesUIOverrides";

import "./CoveragePanels.scss";

const getTitle = ({ field, removeCoverageAndSave, coverageExclusions }) => {
  return (
    <CoverageTitle
      coverage={field}
      handleRemoveCoverage={removeCoverageAndSave}
      coverageExclusions={coverageExclusions}
    />
  );
};

//Coverage types that display whether selected or not.
export const specialCoverageCategoryCodes = [
  "ISPLineStdGrp_UFG",
  "BP7JurisdictionStdGrp",
  "CA7JurisdictionStdGrp",
  "WCMJurisdictionStdGrp",
  "BP7JurisdictionAddlGrp",
  "CA7JurisdictionAddlGrp",
  "WCMJurisdictionAddlGrp"
];

export const PcInputNumberTypes = [
  "Days",
  "Hours",
  "Percent",
  "Other",
  "Money",
  "Count"
];

export const findCoverageControl = (codeIdentifier, coverageExclusions) => {
  if (!coverageExclusions?.formData?.coverageControl) return null;

  return coverageExclusions.formData.coverageControl.find(c => {
    if (!c.codeIdentifier || !codeIdentifier) return false;

    // regex matches the exact string
    const matcher = new RegExp("^" + c.codeIdentifier + "$");
    return matcher.test(codeIdentifier);
  });
};

export const findCoverageTermsControl = (
  codeIdentifier,
  termPublicID,
  coverageExclusions
) => {
  if (!coverageExclusions?.formData?.coverageControl) return null;

  //OOQ-3171 make sure if the coverageControl has not publicID just use the termsControl
  const coveragesWithTerms = coverageExclusions.formData.coverageControl.filter(
    c => {
      if (!c.publicID) {
        return c?.termControl && !!c.termControl.length;
      }
      return (
        !!findCoverageControl(codeIdentifier, coverageExclusions) &&
        c?.termControl &&
        !!c.termControl.length
      );
    }
  );

  // patternCode is a string representing publicID but can also be used as a regex pattern.
  const coverageWithTermControl = coveragesWithTerms.find(c =>
    c.termControl.some(t => {
      const matcher = new RegExp("^" + t.patternCode + "$");
      return matcher.test(termPublicID);
    })
  );
  if (coverageWithTermControl) {
    return coverageWithTermControl.termControl.find(t => {
      const matcher = new RegExp("^" + t.patternCode + "$");
      return matcher.test(termPublicID);
    });
  }

  return null;
};

export const getDefaultValueFromTerm = term => {
  // it's a number
  if (PcInputNumberTypes.includes(term?.valueType))
    return (term?.directValue && term.directValue.toString()) || "";

  // it's a dropdown
  if ((term?.options || []).length > 0 && term.hasOwnProperty("chosenTerm"))
    return term.chosenTerm;

  // it's a date
  if (term?.valueType === "datetime")
    return formatDate(term.directDateValue, "MM/DD/YYYY");

  // it's text
  if (
    term.hasOwnProperty("chosenTermValue") &&
    (term?.valueType === "shorttext" || term?.valueType === "longtext")
  )
    return term.chosenTermValue;

  return "";
};

export const isSuggestedCoverage = ({ coverage, coverageExclusions }) => {
  if (!coverage || !coverageExclusions) return;

  const suggestedCoverages = coverageExclusions?.formData?.coverageControl
    .filter(c => c.control === "suggested")
    .map(c => c.codeIdentifier);
  return (suggestedCoverages || []).includes(coverage.coverageCategoryCode);
};

const coveragePanels = ({
  fields,
  coverageExclusions,
  removeCoverageAndSave,
  quoteData,
  supportingData,
  setCoverageUpdating,
  coverableFixedId,
  setScheduleItemOpenInCoverable
}) => {
  const panels = [];
  let rows = [];
  if (!Array.isArray(fields) || fields.length === 0) return [];

  // apply the coverage control exclusions to filter those that are not visible or are hidden
  const _fields = applyExclusions(fields, coverageExclusions);
  _fields
    .filter(
      x =>
        x.selected === true ||
        x?.suggested === true ||
        // specialCoverageCategoryCodes for state-specific coverages (which act like suggested coverages)
        specialCoverageCategoryCodes.includes(x.coverageCategoryCode)
    )
    //Array.isArray(x.terms) && x.terms.length > 0) //only use the coverages with terms.
    .forEach(f => {
      let flatfields = [];

      if (f.hasTerms) {
        // map terms to fields to put into a row
        f.terms.forEach(t => {
          // allow JSX to be added so regular markup can be added into the array of fields to render
          if (t?.jsx) {
            flatfields.push(t);
            return;
          }

          const termControls = findCoverageTermsControl(
            f.publicID,
            t.publicID,
            coverageExclusions
          );

          if (
            termControls?.control === "hidden" ||
            f.selected === false //Don't show terms when selected is false. OOQ-3049
          )
            return;

          //OOQ-3020 accomodating non unique public ID's
          const path = !f.coverableFixedId
            ? `${f.publicID}.${t.publicID}`
            : `${f.publicID}.${t.publicID}.${f.coverableFixedId}`;

          const fullStoryId = `fullstory_oq_coverage_term_${f.publicID}_${t.publicID}`;

          //options check by name only one we should would and hide the rest.
          const termOnlyShowControls = termControls?.onlyShowOptions || [];
          const defaultOptions = (t?.options || []).filter(o => !!o.code);
          let optionsFilteredByTermControl = defaultOptions;
          if (termOnlyShowControls.length > 0) {
            optionsFilteredByTermControl = [];
            defaultOptions.forEach(o => {
              if (
                termOnlyShowControls
                  .filter(x => x.control === "visible")
                  .map(x => x.name)
                  .includes(o.name)
              )
                optionsFilteredByTermControl.push(o);
            });
          }
          //original options check by code plus check by name
          const termOptionControls = termControls?.options || [];
          optionsFilteredByTermControl = optionsFilteredByTermControl.filter(
            o => {
              const optionControl =
                termOptionControls.find(i => i.code === o.code) ||
                termOptionControls.find(i => i.name === o.name);
              if (optionControl) return optionControl.control !== "hidden";

              return true;
            }
          );

          //options check by name only the ones less than in the list.
          const termLessOptions = termControls?.lessThanOptions || [];
          optionsFilteredByTermControl = optionsFilteredByTermControl.filter(
            o => {
              const optionControl = termLessOptions.find(
                i => stripCurrencyChars(o.name) < stripCurrencyChars(i.name)
              );
              if (optionControl) return optionControl.control !== "hidden";

              return true;
            }
          );

          //options check by name only the ones greater than in the list.
          const termGreaterOptions = termControls?.greaterThanOptions || [];
          optionsFilteredByTermControl = optionsFilteredByTermControl.filter(
            o => {
              const optionControl = termGreaterOptions.find(
                i => stripCurrencyChars(o.name) > stripCurrencyChars(i.name)
              );
              if (optionControl) return optionControl.control !== "hidden";

              return true;
            }
          );

          let flatfield = {
            terms: [t],
            name: t.name,
            label: t.name,
            onChange: t.onChange,
            onBlur: t.onBlur,
            validation: t.validation,
            termPublicID: t.publicID,
            requiredError: t.requiredError,
            fullStoryId,
            path,
            component: "ufg-pl-select-group",
            // TODO : options should filter out any item that does not have a "code"
            // this will remove non-selectable items like "None Selected"
            // that should not be selectable
            options: optionsFilteredByTermControl,
            defaultValue: getDefaultValueFromTerm(t) || "",
            required: t.required
          };

          if (t.valueType === "Money") {
            flatfield.mask = "currency";
          }

          if (t.hasOwnProperty("valueType") && t.valueType === "datetime") {
            const datevalue = formatDate(t.chosenTerm, "OBJECT");

            flatfield = {
              ...flatfield,
              dataType: "date",
              component: "gw-pl-local-date-chooser-group",
              defaultValue: t.chosenTerm ? datevalue : ""
            };
          } else if (
            t.hasOwnProperty("valueType") &&
            PcInputNumberTypes.includes(t?.valueType)
          ) {
            const numbervalue =
              t.directValue && !isNaN(t.directValue)
                ? Number(t.directValue)
                : t.chosenTermValue &&
                  !isNaN(t.chosenTermValue.replace(/,/g, ""))
                ? Number(t.chosenTermValue.replace(/,/g, "")).toFixed(0)
                : "";
            const minMaxmessage =
              t.directValueMin && t.directValueMax
                ? ` between ${t.directValueMin} and ${t.directValueMax}`
                : "";

            flatfield = {
              ...flatfield,
              dataType: "string",
              component: "string",
              mask: "numberCommas",
              min: t.directValueMin,
              max: t.directValueMax,
              defaultValue: numbervalue.toString(),
              validation: {
                validatorfunc: value => {
                  if (isNaN(value)) return false;

                  if (t.directValueMin && t.directValueMax) {
                    return (
                      t.directValueMin <= value && t.directValueMax >= value
                    );
                  }
                  return true; //ignore validation
                },
                errorMessage: `${t.name} must be a number${minMaxmessage}.`
              }
            };
          } else if (
            t.hasOwnProperty("dataType") ||
            t.options.length === 0 ||
            (t.options.length === 1 && t.options[0].name === "None Selected")
          ) {
            flatfield = {
              ...flatfield,
              dataType: "string",
              component: "string",
              maxLength: t?.maxLength || 255,
              mask: t.mask,
              stripMaskFromValue: t.stripMaskFromValue
            };
          }

          // fields are disabled if:
          // 1: PC says so
          // 2: the control says so
          // 3: if they are a dropdown and only have a single option to select (and the default value is set)
          const readonly =
            t?.isCovTermEditable_UFG === false ||
            termControls?.control === "readonly" ||
            (flatfield.component === "ufg-pl-select-group" &&
              flatfield?.options.length === 1 &&
              !!flatfield.defaultValue);

          flatfield = {
            ...flatfield,
            disabled: t.readonly || readonly
          };

          /**
           * apply overrides and customizations to coverages
           */
          if (coverageTermOverrides[flatfield.termPublicID]) {
            flatfield = coverageTermOverrides[flatfield.termPublicID](
              flatfield,
              quoteData,
              supportingData
            );
          }

          flatfields.push(flatfield);
        });
      }

      // display EVERY selected coverage - even if it doesn't have terms
      rows.push({ fields: flatfields });

      // ----------------------
      // SCHEDULE ITEM COVERAGE
      // ----------------------

      // TODO: Pass in the quote object updater to the SI Table below so the updates to the SI can update the quote object

      if (f.hasOwnProperty("clauseScheduleItems")) {
        const hasEmptySI = f.clauseScheduleItems.scheduleItems.length === 0;
        rows.push({
          jsxKey: f.publicID + "schedule-item-table",
          jsx: (
            <div className={hasEmptySI ? "oq__coverable__termWithError" : ""}>
              <ScheduleItemTable
                field={f}
                scheduleItems={f.clauseScheduleItems}
                quoteData={quoteData}
                setCoverageUpdating={setCoverageUpdating}
                coverableFixedId={coverableFixedId}
                setScheduleItemOpenInCoverable={setScheduleItemOpenInCoverable}
              />
            </div>
          )
        });
      }

      const key = f?.coverableFixedId // adding this condition so key doesn't include a confusing "undefined" string
        ? `${f.publicID}.${f?.coverableFixedId}`
        : f.publicID;

      if (!!rows.length)
        panels.push({
          rows,
          columns: 2,
          className: cn(f?.className, {
            [`oq__covg-cat__${f?.coverageCategoryCode}`]:
              f?.coverageCategoryCode,
            oq__coverable__coverageWithError:
              f?.clauseScheduleItems &&
              f?.clauseScheduleItems?.scheduleItems.length === 0
          }),
          coverageName: f.name,
          showBefore: f.showBefore,
          showAfter: f.showAfter,
          jsxAtTop: coveragePanelJsx?.[key]?.jsxAtTop,
          jsxAtBottom: coveragePanelJsx?.[key]?.jsxAtBottom,
          title: getTitle({
            field: f,
            removeCoverageAndSave,
            coverageExclusions
          }),
          key,
          coverageWithScheduleItems: f.hasOwnProperty("clauseScheduleItems")
        });

      flatfields = [];
      rows = [];
    });

  return sortCoverages(panels);
};
export default coveragePanels;

export const updateCoverageValue = ({
  fieldName,
  value,
  allCoverages,
  saveCoverages,
  toastErrr,
  saveNonCoverage
}) => {
  if (!fieldName.includes("."))
    toastErrr({
      action: "updateCoverageValue",
      description: `Error saving coverage : fieldName.includes(".")`,
      misc: { fieldName, value }
    });

  const publicIds = fieldName.split(".");

  // find the coverage to update
  //OOQ-3020 accomodating non unique public ID's by using coverableFixedId
  const coverageFoundInDTO = allCoverages?.find(cov => {
    if (!!cov.coverableFixedId) {
      return (
        cov.publicID === publicIds[0] &&
        String(cov.coverableFixedId) === publicIds[2]
      );
    }
    return cov.publicID === publicIds[0];
  });

  /*
  Create a copy of the coverage from the DTO to submit in the API call
  */
  const coverageToSave = structuredClone(coverageFoundInDTO);

  // update the term value
  const termToUpdate = coverageToSave?.terms.find(t => {
    return t.publicID === publicIds[1];
  });

  // this isn't a typical coverage...
  // it's some hardcoded thing mixed in with the coverages
  // (like the Workman Comp Line Details)
  if ((!coverageToSave || !termToUpdate) && saveNonCoverage) {
    // just return the field data to the saveNonCoverage method

    saveNonCoverage({ fieldName, value });

    return;
  }

  let doUpdate = false;

  // check if it's a date, if it is...  //.split("T")[0]
  // normalize the date to a comparable string and...
  // check to see if the dates are different before proceeding
  if (
    termToUpdate?.valueType === "datetime" &&
    formatDate(
      (termToUpdate?.directDateValue || "").split("T")[0],
      "MM/DD/YYYY"
    ) !== formatDate(value, "MM/DD/YYYY") &&
    // only update if value is a valid date or an empty value
    (isValidDate(value) || value === "") &&
    termToUpdate.chosenTerm !== formatDate(value, "MM/DD/YYYY") // entered value is different than the current value for this term
  ) {
    doUpdate = true;
    termToUpdate.directDateValue = formatDate(value, "ISO");
  }

  // if radio button - we assume that it's either "Yes" or "No"
  // and if it has a new value  -  then update to the new value
  if (
    termToUpdate?.valueType === "bit" && // OOQ-10388 - first story requiring using Radio button
    termToUpdate.options.length > 0 &&
    (termToUpdate.hasOwnProperty("chosenTerm") ||
      termToUpdate.hasOwnProperty("chosenTermValue")) &&
    termToUpdate.chosenTerm !== value // selected value is different than the current value for this term
  ) {
    termToUpdate.chosenTerm = value;
    termToUpdate.chosenTermValue = value === "true" ? "Yes" : "No";
    termToUpdate.directBooleanValue = value === "true"; // this is a work-around for CA7GaragingLocation7 term
    doUpdate = true;
  }

  // if it's a dropdown/select and ...
  // and if it has a new value  -  then update to the new value
  if (
    termToUpdate.options.length > 0 &&
    termToUpdate?.valueType !== "datetime" && //3806 coverage `Employment-Related Practices Liability Endorsement`
    //has both options and dateTime
    !PcInputNumberTypes.includes(termToUpdate?.valueType) &&
    (termToUpdate.hasOwnProperty("chosenTerm") ||
      termToUpdate.hasOwnProperty("chosenTermValue")) &&
    termToUpdate.chosenTerm !== value // selected value is different than the current value for this term
  ) {
    /**
     * "chosenTermValue" is the "name" of the option selected...
     * the form's dropdown component only sends the "value" - the "label" isn't included...
     * but we can find the label of the option by finding the coverage, term, and option in the "allCoverages" array
     */
    const selectedOption = termToUpdate.options.find(o => o.code === value);
    const label = selectedOption.name || value;

    termToUpdate.chosenTerm = value;
    termToUpdate.chosenTermValue = label;
    termToUpdate.directStringValue = value; // this is a work-around for CA7GaragingLocation7 term
    doUpdate = true;
  }

  // it's an input and...
  // if it has a new value  - set it to be the new value

  if (PcInputNumberTypes.includes(termToUpdate?.valueType)) {
    if (
      (!termToUpdate.directValue && !!value) ||
      (!!termToUpdate.directValue &&
        Number(termToUpdate.directValue) !== Number(value))
    ) {
      termToUpdate.directValue = value;
      doUpdate = true;
    }
  }

  // it's text and...
  // if it has a new value  - set it to be the new value
  if (
    termToUpdate.valueType === "shorttext" ||
    termToUpdate.valueType === "longtext"
  ) {
    if (
      (!termToUpdate?.chosenTermValue && !!value) ||
      (termToUpdate.chosenTermValue !== undefined &&
        termToUpdate.chosenTermValue !== value)
    ) {
      termToUpdate.directStringValue = value;
      doUpdate = true;
    }
  }

  if (doUpdate) {
    // set the value in the object above
    termToUpdate.updated = true;
    // do the coverage update
    saveCoverages(coverageToSave, termToUpdate);
  }
  return false;
};

export const removeCoverage = (field, getCoverages, saveCoverages) => {
  const coverageToRemove = { ...field };
  if (!coverageToRemove) return;
  const unfilteredcoverages = getCoverages(coverageToRemove);
  const unfiltered = unfilteredcoverages.map(cov => {
    if (coverageToRemove.publicID === cov.publicID) {
      cov.selected = false;
      cov.terms = [];
    }
    return cov;
  });
  saveCoverages(unfiltered, coverageToRemove, null, "removed");
};

export const applyExclusions = (coverages, exclusions) => {
  const _remainingCoverages = [];
  coverages.forEach(f => {
    const controlAttrib = findCoverageControl(f.codeIdentifier, exclusions);

    if (
      !controlAttrib ||
      (controlAttrib?.control === "visible" &&
        controlAttrib?.control !== "hidden")
    ) {
      _remainingCoverages.push(f);
    }
    if (controlAttrib?.control === "readonly") {
      _remainingCoverages.push({ ...f, readonly: true });
    }

    if (controlAttrib?.control === "suggested") {
      _remainingCoverages.push({ ...f, suggested: true });
    }

    if (controlAttrib?.control === "required") {
      _remainingCoverages.push({ ...f, required: true });
    }
  });
  return _remainingCoverages;
};

export const getCoverageDetailsForLogging = ({
  coverage,
  termToUpdate: t,
  action: a
}) => {
  // if we have a term, get it's value
  const value = t
    ? t.valueType === "datetime" // if it's a date value, format it
      ? formatDate(t.chosenTerm, "OBJECT")
      : t?.directValue || t?.chosenTermValue || t?.chosenTerm
    : undefined; // otherwise use the directValue or chosenTermValue

  const action = a ? (a === "added" && !!value ? "updated" : a) : "";

  return {
    action,
    coverageName: coverage?.name || "",
    category: coverage?.coverageCategoryCode || "",
    coverageId: coverage?.codeIdentifier || "",
    termId: t?.patternCode || t?.coveragePublicID || "",
    value: value || ""
  };
};

export const coveragesMissingRequireTerms = ({ coverages }) => {
  return coverages.some(
    c =>
      (c?.clauseScheduleItems &&
        c?.clauseScheduleItems?.scheduleItems.length === 0) ||
      (c?.terms || []).some(
        t =>
          t.required && (t.chosenTermValue === null || t.chosenTermValue === "")
      )
  );
};

/*
Compare two coverage list 
*/
export const areSelectedCoveragesEqual = (coveragesA, coveragesB) => {
  const selA = (coveragesA || []).filter(x => !!x.selected);
  const selB = (coveragesB || []).filter(x => !!x.selected);
  let coveragesAreEqual = true;

  selA.forEach(a => {
    const covB = selB.find(b => {
      return b.codeIdentifier === a.codeIdentifier;
    });

    if (!covB) {
      coveragesAreEqual = false;
      return;
    }
    (covB?.terms || []).forEach(bt => {
      const termA = (a?.terms || []).find(at => {
        return (
          at.publicID === bt.publicID &&
          at.chosenTermValue === bt.chosenTermValue
        );
      });
      if (!termA) {
        coveragesAreEqual = false;
      }
    });
  });
  return coveragesAreEqual;
};
