import _get from "lodash/get";
import _omit from "lodash/omit";
import merge from "deepmerge";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory } from "react-router-dom";
import { toTitleCase } from "../../components/Factory";
import { v4 } from "uuid";
import { logUserInteraction } from "../../services/loggingService";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

import * as api from "../../services/onlineQuotingService";
import * as keys from "../../constants/localStorageKeys";
import {
  flowValidation,
  validationWarningsToIgnore,
  productKeys,
  hardCodedErrorsToIgnore_stateSpecific
} from "./constants";
import { quotePatch } from "./quotePatching";
import { slugify } from "./util";
import { logger } from "../../loggers";
import * as SiUtils from "./scheduleItems/scheduleItemUtils";

/*
const oqStore = useGlobalStorage({
  storageOptions: { name: "UFG_STORE" }
});
*/

const useLocalStorageState = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

export const useQuote = ({ sessionData }) => {
  const history = useHistory();

  const [quoteData, setQuoteData] = useLocalStorageState(
    keys.CURRENT_QUOTE_DATA,
    null
  );

  const [step, setStep] = useLocalStorageState(keys.CURRENT_STEP, 1);
  const [supportingData, setSupportingData] = useLocalStorageState(
    keys.QUOTE_SUPPORT_DATA,
    window.localStorage.getItem(keys.QUOTE_SUPPORT_DATA) || null
  );
  const {
    proQuoteStpEnabled,
    proQuoteStpAgentsAllowed,
    proQuoteOverrideEffectiveDateByAgent
  } = useFlags();

  const [isNewQA, setIsNewQA] = useState(
    (quoteData?.baseData?.accountHolder?.publicID || "").startsWith("newqa-pc")
  );

  useEffect(() => {
    const newQA = (
      quoteData?.baseData?.accountHolder?.publicID || ""
    ).startsWith("newqa-pc");
    if (newQA !== isNewQA) setIsNewQA(newQA);
  }, [isNewQA, quoteData]);

  const resetQuoteDataAfterFailure = useCallback(
    () => setQuoteData({ ...quoteData }),
    [quoteData, setQuoteData]
  );

  const clearLocalQuote = useCallback(
    ({ goto } = {}) => {
      if (goto) history.push(goto);
      else {
        if (supportingData) setSupportingData(null);
        if (quoteData) setQuoteData(null);
        if (step) setStep(1);
      }
      window.localStorage.setItem(keys.QUOTE_SUPPORT_DATA, null);
      window.localStorage.setItem(keys.CURRENT_QUOTE_DATA, null);
      window.localStorage.setItem(keys.CURRENT_STEP, 1);
    },
    [
      history,
      quoteData,
      setQuoteData,
      setStep,
      setSupportingData,
      step,
      supportingData
    ]
  );

  const [effectiveDateOverride, setEffectiveDateOverride] = useState(false);
  const [forceFailures, setForceFailures] = useState(false);

  useEffect(() => {
    setEffectiveDateOverride(proQuoteOverrideEffectiveDateByAgent);
  }, [proQuoteOverrideEffectiveDateByAgent]);

  // -------------------------------
  //   LOGGING DATA FOR TOAST ERRORS
  // -------------------------------

  const [loggingData, setLoggingData] = useState({
    ...sessionData,
    step,
    quoteId: supportingData?.quoteNumber || supportingData?.referenceId
  });

  useEffect(() => {
    const newLoadingData = {
      ...loggingData,
      step,
      quoteId: supportingData?.quoteNumber || supportingData?.referenceId
    };

    if (JSON.stringify(loggingData) !== JSON.stringify(newLoadingData)) {
      setLoggingData(newLoadingData);
    }
  }, [step, supportingData, quoteData, loggingData]);

  // ---------------------------------
  //   STP : Straight Thru Processing
  // ---------------------------------

  // keep stpException updated if flags change
  const [stpException, setStpException] = useState(
    proQuoteStpEnabled && proQuoteStpAgentsAllowed
  );
  useEffect(() => {
    setStpException(proQuoteStpEnabled && proQuoteStpAgentsAllowed);
  }, [proQuoteStpEnabled, proQuoteStpAgentsAllowed]);

  // ---------------------------
  //   UNDERWRITER ISSUES
  // ---------------------------
  // OOQ-1738
  // when on step 6, any underwriter issue should show the alert as yellow warning
  // on step 4 & 5, ignore the credit report warning (""POL: Credit Score Needed"")

  const getFilteredUnderwriterIssues = useCallback(
    () =>
      (quoteData?.errorsAndWarnings?.underwritingIssues || []).filter(
        i =>
          i.shortDescription !== "POL: Credit Score Needed" &&
          i.currentBlockingPoint !== "NonBlocking"
        //  &&  i.hasApprovalOrRejection === false
      ),
    [quoteData]
  );

  const hasBlockingUnderwriterIssues = useCallback(() => {
    const uwIssues = getFilteredUnderwriterIssues();
    // if STP is enabled, then no issues block
    if (stpException) return false;
    // else, check the issues to see if any are blocking
    return uwIssues.some(
      i =>
        i.hasApprovalOrRejection === false ||
        i.currentBlockingPoint === "Rejected" ||
        i.approvalBlockingPoint === "Rejected"
    );
  }, [getFilteredUnderwriterIssues, stpException]);

  const [hasBlockingUwIssues, setHasBlockingUwIssues] = useState(
    hasBlockingUnderwriterIssues()
  );

  useEffect(() => {
    setHasBlockingUwIssues(hasBlockingUnderwriterIssues());
  }, [hasBlockingUnderwriterIssues]);

  // ---------------------------
  //   FIND IN QUOTE FUNCTIONS
  // ---------------------------

  const getQuotePart = useCallback(
    (path, isArray = false) => {
      // return nothing if no quoteData
      if (!quoteData) return isArray ? [] : null;
      // get things from quoteData
      const items = _get(quoteData, path);
      if (isArray && (!Array.isArray(items) || items.length === 0)) return [];
      return items;
    },
    [quoteData]
  );

  const getLobDataPaths = useCallback((storedQuote, pathEnd) => {
    if (!storedQuote || !storedQuote.lobData) return [];

    const paths = [];
    const keys = Object.keys(storedQuote.lobData);
    keys.forEach(k => {
      paths.push(`lobData.${k}.${pathEnd}`);
    });
    return paths;
  }, []);

  /**
 *
{
  "InteractionType": "error", // REQUIRED: error, warning, info, validation, other
  "Action": "", // REQUIRED: 100 char
  "Message": "", // REQUIRED: 500 char
  "CurrentUserUrl": "",  // REQUIRED: 500 char
  "AgentId": "string", // REQUIRED: 10 char
  "APIUrl": "", // 500 char
  "APIPayload": {},
  "IsApiCall": true,
  "ReferenceDT": "", // 23 char
  "QuoteClassDescription": "", // 250 char
  "HardStop": true,
  "QuoteIds": "", // 100 char
  "QuoteState": "n", // 2 char (abbrev location state)
  "QuoteClassCode": "2",  // 5 char
  "QuoteDTOLink": "",  // 500 char
  "QuoteBusinessType": "", // 100 char
  "QuoteLinesOfBusiness": ""  // 150 char

  future:
  EmployeeId // 50 char
  additionalInformation // {}
}
 */
  const toastErrr = useCallback(
    ({
      type = "error",
      action = "unknown",
      description = "no description",
      error,
      misc,
      payload,
      displayMessage,
      onOpen
    }) => {
      // const message = displayMessage?.props
      //   ? ReactDOMServer.renderToStaticMarkup(displayMessage)
      //   : "GenericContactHelpDeskMessage";

      const err =
        error?.error?.response?.data ||
        error?.error?.response ||
        error?.response?.data ||
        error?.response ||
        error?.data ||
        error ||
        {};

      let e = "";

      try {
        e = JSON.stringify(err || {});
      } catch (er) {
        e = "problem trying to convert error to string";
      }

      const requestPayload = {
        InteractionType: type,
        Action: action,
        Message: description,
        CurrentUserUrl: window?.location?.href || "cannot get url",
        AgentId: loggingData?.userInfo?.userAgentCode || "0000000000",
        EmployeeId: !!loggingData?.userInfo?.employeeUsername
          ? loggingData?.userInfo?.employeeUsername
          : undefined,
        AdditionalInformation: misc,
        Error: e,
        APIUrl: err?.config
          ? err?.config?.baseURL + err?.config?.url
          : undefined,
        APIPayload: payload || err?.request,
        IsApiCall: !!err?.config?.baseURL,
        ReferenceDT: supportingData?.referenceId,
        QuoteClassDescription: supportingData?.classCode?.description,
        QuoteIds: supportingData?.quoteNumber,
        QuoteState:
          supportingData?.customerInformation?.accountHolder?.primaryAddress
            ?.state,
        QuoteClassCode: supportingData?.classCode?.code,
        QuoteBusinessType: supportingData?.classCode?.businessType_UFG,
        QuoteLinesOfBusiness: supportingData?.policyLines?.join(",")
      };

      const ToastMessage = displayMessage ? (
        <span className="oq__toast-error__message">{displayMessage}</span>
      ) : (
        <HelpDeskMessage display="anErrorOccurred" noLinks />
      );

      setTimeout(() =>
        toast.error(ToastMessage, {
          toastId: slugify(action || "oq__toast__errrrr"),
          position: "top-center",
          className: "ufg-toast__error",
          autoClose: false,
          icon: false,
          onOpen
        })
      );

      logUserInteraction(requestPayload).catch(error =>
        console.error("logging error", error, requestPayload)
      );
    },
    [supportingData, loggingData]
  );

  //-----------------------------
  // UPDATE SUPPORTING DATA
  //-----------------------------
  const [supportingDataIsUpdating, setSupportingDataIsUpdating] =
    useState(false);

  const updateSupportingDataPromise = useCallback(
    ({ dataToMergeAndSave, sendToDB = true }) => {
      // console.log({ dataToMergeAndSave });
      setSupportingDataIsUpdating(true);
      return new Promise((resolve, reject) => {
        const payload = {
          ...supportingData,
          ...dataToMergeAndSave
        };
        if (sendToDB) {
          api
            .saveQuote(payload)
            .then(results => {
              setSupportingData({ ...results.data });
              // always make sure the step is up-to-date with what is in supporting data
              const stepNum = Number(results?.data?.currentPage);
              if (!isNaN(stepNum)) setStep(stepNum);

              setSupportingDataIsUpdating(false);
              resolve({
                success: true,
                data: { ...results.data },
                error: null
              });
            })
            .catch(error => {
              console.error(error);
              setSupportingDataIsUpdating(false);
              toastErrr({
                action: "updateSupportingDataPromise",
                description: "unable to save supportingData",
                error
              });

              reject({ success: false, data: null, error });
            });
        } else {
          setSupportingData({ ...supportingData, ...dataToMergeAndSave });
          setSupportingDataIsUpdating(false);
          resolve({ success: true, data: dataToMergeAndSave });
        }
      });
    },
    [supportingData, setSupportingData, setStep, toastErrr]
  );

  // --------------------------
  //   UPDATE QUOTE FUNCTIONS
  // --------------------------

  const [quoteIsUpdating, setQuoteIsUpdating] = useState();
  /**
   * Note:
   * toastId is optional... when not included, the message is slugified and used as the id
   */
  const showUpdatingToast = useCallback(({ message, toastId: id }) => {
    const toastId = id || slugify(message);

    setQuoteIsUpdating(true);

    const options = {
      position: "bottom-center",
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      progress: undefined,
      icon: true,
      theme: "colored",
      containerId: "OQ",
      isLoading: true,
      pauseOnHover: false,
      autoClose: false,
      draggable: false,
      pauseOnFocusLoss: false,
      className: "oq__toast__updating-quote"
    };

    // if the toast is still active, then update the toast
    if (toast.isActive(toastId)) {
      toast.update(toastId, {
        render: message || "Updating Quote",
        ...options
      });
    } else {
      toast.info(message || "Updating Quote", { toastId, ...options });
    }
  }, []);

  const closeUpdatingToast = useCallback(({ toastId, message }) => {
    setQuoteIsUpdating(false);
    const id = toastId || slugify(message);
    toast.dismiss(id || null); // NOTE: if this is null, it'll close ALL toasts
  }, []);

  const updateQuote = useCallback(
    (
      { newData, newSupportingData, mergeData = true, sendToPC = true } = {},
      callback
    ) => {
      if (!newData) return;

      // if we're merging, then check to make sure we have the quote data from storage first
      const dataToSave =
        mergeData && quoteData ? merge(quoteData, newData) : newData;

      if (sendToPC) {
        //post quote to PC for saving
        const quoteId = dataToSave.quoteID;

        const pathsToSkip = getLobDataPaths(dataToSave, "coverages");
        const dataWithoutCoverages = _omit(dataToSave, pathsToSkip);

        api
          .updateQuote(quoteId, dataWithoutCoverages)
          .then(results => {
            // ------------------------
            // TODO: Add error checking
            // if PC returns an error message,
            // don't update the quote and return the error instead
            // ------------------------
            setQuoteData({ ...results.data.dto });
            setSupportingData({ ...results.data.supportingData });

            if (callback)
              callback({ success: true, data: results.data, error: null });
          })
          .catch(error => {
            //TODO: need a better way to handle errors like this one accross the app.
            console.error(error);
            console.error("Error updating quote " + quoteId);
            if (callback) callback({ success: false, data: null, error });
          });
      } else {
        setQuoteData(dataToSave);
        if (newSupportingData) setSupportingData({ ...newSupportingData });

        if (callback) callback({ success: true });
      }
    },
    [getLobDataPaths, quoteData, setQuoteData, setSupportingData]
  );

  const patchQuote = useCallback(
    ({ newData: data, quoteId: id, callback }) => {
      if (!data || !callback) return;

      const quoteId = forceFailures ? undefined : id;
      const newData = forceFailures ? undefined : data;

      quotePatch({
        quoteId,
        newData,
        callback: ({ success, results, error }) => {
          if (success) {
            // update the local quote data to match what was updated
            setQuoteData({ ...results.data.dto });
            setSupportingData({ ...results.data.supportingData });

            callback({ success: true, results, error: null });
          } else {
            callback({ success: false, data: null, error });
            setQuoteData({ ...quoteData });
          }
        }
      });
    },
    [forceFailures, quoteData, setQuoteData, setSupportingData]
  );

  const patchQuotePromise = useCallback(
    ({ newData: data, quoteId: id, updateMessage: message }) => {
      return new Promise((resolve, reject) => {
        if (!data)
          reject({ success: false, data: null, error: "No data sent" });

        const quoteId = forceFailures ? undefined : id;
        const newData = forceFailures ? undefined : data;

        const toastId = Math.random();
        showUpdatingToast({ message, toastId });

        quotePatch({
          quoteId,
          newData,
          callback: ({ success, results, error }) => {
            if (success) {
              // update the local quote data to match what was updated
              setQuoteData({ ...results.data.dto });
              setSupportingData({ ...results.data.supportingData });
              resolve({ success: true, results, error: null });
            } else {
              setQuoteData({ ...quoteData });
              reject({ success: false, data: null, error });
            }
            closeUpdatingToast({ toastId });
          }
        });
      });
    },
    [
      forceFailures,
      quoteData,
      setQuoteData,
      setSupportingData,
      showUpdatingToast,
      closeUpdatingToast
    ]
  );

  const updatePriorLosses = useCallback(
    ({ lob, payload }) => {
      return new Promise((resolve, reject) => {
        const message = `Updating Prior Losses for ${productKeys[lob].label}`;
        showUpdatingToast({ message });

        api
          .priorlosses(quoteData.quoteID, payload)
          .then(results => {
            const updatedQuoteData = {
              ...quoteData,
              riskAnalysis: { ...results.data }
            };
            setQuoteData(updatedQuoteData);
            closeUpdatingToast({ message });
            resolve({ success: true, results, error: null });
          })
          .catch(error => {
            closeUpdatingToast({ message });
            console.error(error, { error });
            toastErrr({
              action: "updatePriorLosses",
              description: "prior losses update failed",
              payload,
              error
            });

            reject({ success: false, error });
          });
      });
    },
    [closeUpdatingToast, quoteData, setQuoteData, showUpdatingToast, toastErrr]
  );

  //-----------------------------
  //       VALIDATION
  //-----------------------------

  const pageValidation = useCallback(
    (flowId, callback) => {
      api
        .getFlowValidation(quoteData.quoteID, flowId)
        .then(results => {
          const dontContinueReasons = [];

          setQuoteData({
            ...quoteData,
            errorsAndWarnings: results.data.errorsAndWarnings
          });

          const issues = (
            results?.data?.errorsAndWarnings?.validationIssues?.issues || []
          ).filter(i => !validationWarningsToIgnore.step4.includes(i.reason));

          if (issues.length === 0) {
            callback({ success: true });
            return;
          }

          issues.forEach(i => {
            dontContinueReasons.push({
              id: i?.relatedEntity?.fixedId,
              reason: i?.reason
            });
          });

          if (dontContinueReasons.length > 0) {
            // BAD DONT CONTINUE
            toastErrr({
              type: "validation",
              action: "pageValidation",
              description: "page navigation validations stopped user",
              misc: issues,
              displayMessage: (
                <div className="oq__flow-error__msg">
                  <div className="oq__flow-error__msg-text">
                    Unable to continue. Please fix these issues in order to
                    continue:
                  </div>
                  <div className="oq__flow-error__msg-list">
                    <ul>
                      {dontContinueReasons.map(e => (
                        <li key={e.reason}>{e.reason}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ),
              onOpen: () =>
                callback({
                  success: false
                })
            });
          }
        })
        .catch(errors => {
          callback({ success: false, errors });
        });
    },
    [quoteData, setQuoteData, toastErrr]
  );

  const coverableValidation = useCallback(
    ({ flowId = "all" }) => {
      return new Promise((resolve, reject) => {
        api
          .getFlowValidation(quoteData.quoteID, flowValidation[flowId])
          .then(results => {
            const dontContinueReasons = [];

            setQuoteData({
              ...quoteData,
              errorsAndWarnings: results.data.errorsAndWarnings
            });

            const issues =
              results?.data?.errorsAndWarnings?.validationIssues?.issues || [];

            if (issues.length === 0) resolve({ success: true });

            issues.forEach(i => {
              dontContinueReasons.push({
                id: i?.relatedEntity?.fixedId,
                reason: i?.reason
              });
            });

            resolve({ success: true, reasons: dontContinueReasons });
          })
          .catch(errors => reject({ success: false, errors }));
      });
    },
    [quoteData, setQuoteData]
  );

  const validateCoverablesPromise = useCallback(
    ({
      coverableName,
      coverablefixedId,
      isCancelRequest,
      classIds = [],
      stateSpecific
    }) => {
      return new Promise((resolve, reject) => {
        const coverableLabel = stateSpecific || `${coverableName}s`;
        const message = toTitleCase(
          `Checking validations on ${coverableLabel}`,
          "on"
        );
        const toastId = Math.random();
        showUpdatingToast({ message, toastId });

        coverableValidation({ flowId: coverableName })
          .then(({ reasons }) => {
            const currentCoverableIssues =
              (reasons || []).filter(r => {
                const isClass =
                  r.id === coverablefixedId || classIds?.includes(r.id);

                const ignoreState = stateSpecific
                  ? !hardCodedErrorsToIgnore_stateSpecific.some(i =>
                      i.test(r.reason)
                    )
                  : true;

                return isClass && ignoreState;
              }) || [];

            if (currentCoverableIssues.length > 0) {
              //display the toast message
              toastErrr({
                type: "validation",
                action: "coverableValidation",
                description: "page navigation validations stopped user",
                misc: {
                  coverableName,
                  stateSpecific,
                  coverablefixedId,
                  issues: currentCoverableIssues || reasons
                },
                displayMessage: (
                  <div className="oq__flow-error__msg">
                    <div className="oq__flow-error__msg-text">
                      {!!isCancelRequest
                        ? `The ${coverableName} has the following errors`
                        : "Unable to continue. Please fix these issues in order to continue:"}
                    </div>
                    <div className="oq__flow-error__msg-list">
                      <ul>
                        {currentCoverableIssues.map(e => (
                          <li key={e.reason}>{e.reason}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )
              });

              resolve({ success: false, reasons });
            } else {
              resolve({ success: true });
            }
          })
          .catch(({ error }) => {
            toastErrr({
              action: "coverableValidation",
              description: "page navigation validations stopped user",
              misc: {
                coverableName,
                stateSpecific,
                coverablefixedId
              },
              error,
              displayMessage: `An error occurred.  Unable to check ${coverableLabel} validations.`
            });

            closeUpdatingToast({ toastId });
            reject({ success: false, error });
          })
          .finally(() => closeUpdatingToast({ toastId }));
      });
    },
    [closeUpdatingToast, coverableValidation, showUpdatingToast, toastErrr]
  );

  const navigateToStep = useCallback(
    ({ step: Step }, callback) => {
      if (!Step && callback) {
        callback({ success: false });
        return;
      }

      if (Step > 3) {
        api
          .getQuoteNavigation(quoteData.quoteID, Step)
          .then(results => {
            setQuoteData({ ...results.data.dto });
            setSupportingData({ ...results.data.supportingData });

            callback({
              success: true,
              data: results.data
            });
          })
          .catch(error => {
            if (callback) {
              toastErrr({
                action: "navigateToStep",
                description: "unable to navigate",
                error
              });

              callback({ success: false, data: null, error });
            }
          });
      } else {
        // send update of supporting data to mule and save it locally
        updateSupportingDataPromise({
          dataToMergeAndSave: {
            ...supportingData,
            currentPage: Step.toString()
          }
        }).then(() => {
          if (callback) callback({ success: true });
          setStep(Step);
        });
      }
    },
    [
      quoteData?.quoteID,
      setQuoteData,
      setSupportingData,
      toastErrr,
      updateSupportingDataPromise,
      supportingData,
      setStep
    ]
  );

  // --------------------------
  //   CREATE QUOTE
  // --------------------------

  const createQuote = useCallback(
    ({ dataToSave }) => {
      return new Promise((resolve, reject) => {
        api
          .createQuote(dataToSave)
          .then(results => {
            setQuoteData({ ...results.data.dto });
            setSupportingData({ ...results.data.supportingData });

            resolve({
              success: true,
              data: results?.data || {}
            });
          })
          .catch(error => {
            reject({
              success: false,
              error: error?.response?.data
                ? { ...(error?.response?.data || {}) }
                : error
            });
          });
      });
    },
    [setQuoteData, setSupportingData]
  );

  //------------------------
  //  GET VENDOR QUOTE
  //------------------------
  const getVendorQuote = useCallback(
    id => {
      if (step) setStep(null);
      return new Promise((resolve, reject) => {
        api
          .getVendorQuote(id)
          .then(results => {
            const quoted =
              results?.data?.dto?.baseData?.periodStatus === "Quoted";
            if (results?.data?.dto?.lobData) {
              setQuoteData({ ...results.data.dto });
              setSupportingData({ ...results.data.supportingData });
              setStep(quoted ? "6" : "4");
              resolve({
                quoted
              });
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    [setQuoteData, setSupportingData, step, setStep]
  );

  //------------------------
  //  GET QUOTE
  //------------------------

  const getQuoteDataById = useCallback(
    (id, callback) => {
      api
        .getQuoteData(id)
        .then(results => {
          if (results?.data?.dto?.lobData) {
            setQuoteData({ ...results.data.dto });
            setSupportingData({ ...results.data.supportingData });
            callback({
              success: true,
              step: parseInt(results?.data?.supportingData?.currentPage) || 3
            });
          }
        })
        .catch(() => {
          if (callback) callback({ success: false });
        });
    },
    [setQuoteData, setSupportingData]
  );

  const getQuoteDataByRef = useCallback(
    (id, callback) => {
      api
        .getQuoteByReferenceId(id)
        .then(results => {
          if (!!results?.data?.quoteNumber) {
            getQuoteDataById(results?.quoteNumber, callback);
          } else {
            setSupportingData({ ...results?.data });
            callback({
              success: true,
              step: parseInt(results?.data?.currentPage) || 1
            });
          }
        })
        .catch(() => {
          if (callback) callback({ success: false });
        });
    },
    [getQuoteDataById, setSupportingData]
  );

  const getQuote = useCallback(
    (id, callback) => {
      if (id) {
        // ref id for quotes on step 1 and 2
        // http://[DOMAIN]/online_quoting/loader/50237630781611324972099
        if (id.length > 15) getQuoteDataByRef(id, callback);
        // submission id from PC
        // http://[DOMAIN]/online_quoting/loader/0001849730
        else getQuoteDataById(id, callback);
      }
    },
    [getQuoteDataById, getQuoteDataByRef]
  );

  /**
   * 0 = fine
   * 1 = trying to recover quote
   * -1 = failure after recover attempt
   */
  const [quoteRecovery, setQuoteRecovery] = useState(0);

  const refreshQuoteFromPC = useCallback(() => {
    return new Promise((resolve, reject) => {
      const toastId = Math.random();
      showUpdatingToast({
        message: "Attempting to reload quote data",
        toastId
      });
      api
        .getQuoteData(quoteData?.quoteID)
        .then(results => {
          // update storage to the loaded quote object

          if (dtoIsValid(results?.data?.dto)) {
            setQuoteData({ ...results.data.dto });
            setSupportingData({ ...results?.data?.supportingData });
            resolve({
              success: true
            });
            setQuoteRecovery(0);
          } else {
            setQuoteRecovery(-1);
          }
        })
        .catch(() => {
          setQuoteRecovery(-1);

          reject({ success: false });
        })
        .finally(() => closeUpdatingToast({ toastId }));
    });
  }, [
    closeUpdatingToast,
    quoteData?.quoteID,
    setQuoteData,
    setSupportingData,
    showUpdatingToast
  ]);

  const dtoIsValid = obj => {
    return (
      obj?.baseData && obj?.lobData && Object.keys(obj?.lobData).length > 0
    );
  };

  /**
   * useEffect watches QuoteData and flags it if it ever becomes invalid
   */
  useEffect(() => {
    if (!quoteData) return;
    const { lobData, quoteID } = quoteData;
    if (!quoteRecovery) {
      if (
        lobData &&
        quoteID &&
        lobData.constructor === Object &&
        Object.keys(lobData).length === 0
      ) {
        setQuoteRecovery(true);
        refreshQuoteFromPC();
      }
    }
  }, [quoteData, quoteRecovery, refreshQuoteFromPC]);

  //------------------------
  //  RATE QUOTE
  //------------------------

  const rateQuote = useCallback(() => {
    return new Promise((resolve, reject) => {
      const message = "Rating the Quote";
      showUpdatingToast({ message });

      api
        .rateQuote(quoteData.quoteID)
        .then(results => {
          const issues =
            results?.data?.dto?.errorsAndWarnings?.validationIssues?.issues;

          const filteredIssues = (issues || []).filter(i => i.type === "error");

          // if dto is good, then update... if not, return an error
          if (results?.data?.dto && dtoIsValid(results?.data?.dto)) {
            setQuoteData(results.data.dto);
            if (results?.data?.supportingData)
              setSupportingData(results?.data?.supportingData);

            resolve({
              success: true,
              issues: filteredIssues,
              quoted: results?.data?.dto?.baseData?.periodStatus === "Quoted"
            });
          } else {
            // bad dto
            reject({
              success: false,
              error: "Bad DTO received from rating call."
            });
          }
        })
        .catch(error => {
          reject({ success: false, error });
        })
        .finally(() => closeUpdatingToast({ message }));
    });
  }, [
    closeUpdatingToast,
    quoteData?.quoteID,
    setQuoteData,
    setSupportingData,
    showUpdatingToast
  ]);

  const issueQuote = useCallback(
    ({ quoteID, payload }) => {
      return new Promise((resolve, reject) => {
        const message = "Binding quote...";
        showUpdatingToast({ message });

        const _quoteID = forceFailures ? undefined : quoteID;
        api
          .issueQuote(_quoteID, payload)
          .then(result => {
            if (dtoIsValid(result.data)) {
              // update local quote data with PC response data
              setQuoteData(result?.data);

              /** if response dto says it's bound, then we're all good */
              if (result?.data?.baseData?.periodStatus === "Bound") {
                // send event tracking to GA
                logger.event({
                  category: "OQ_quote",
                  action: "bind"
                });
                resolve({
                  success: true,
                  bound: result?.data?.baseData?.periodStatus === "Bound"
                });
              } else {
                /** otherwise, it has issues that need resolved */
                const issues = (
                  result?.data?.errorsAndWarnings?.validationIssues?.issues ||
                  []
                ).filter(i => i.type === "error");
                resolve({
                  success: true,
                  issues,
                  quoted: result?.data?.dto?.baseData?.periodStatus === "Quoted"
                });
              }
            } else {
              /** received an invalid response from Policy Center */
              reject({
                success: false,
                error: "invalid dto response from PC"
              });
            }
          })
          .catch(error => {
            console.log(error);
            reject({ success: false, error });
          })
          .finally(() => closeUpdatingToast({ message }));
      });
    },
    [closeUpdatingToast, forceFailures, setQuoteData, showUpdatingToast]
  );

  const changeLineOfBusiness = useCallback(
    ({ action, product, rateAfter }) => {
      return new Promise((resolve, reject) => {
        const updateError = ({ error }) => {
          toastErrr({
            action: "changeLineOfBusiness-updateError",
            description: `tried to ${action}  ${product?.lineOfBusiness}`,
            error
          });

          console.error(error);
          reject({ error, success: false });
        };

        const rerateError = ({ error }) => {
          toastErrr({
            action: "changeLineOfBusiness-rerateError",
            description: `tried to rate after ${action} ${product?.lineOfBusiness}`,
            error
          });

          console.error(error);
          reject({ error, success: false });
        };

        const message = `${action} ${product?.label || "Line"}`;
        showUpdatingToast({ message });

        if (!product?.lineOfBusiness) {
          updateError({ error: "No Line of Business found" });
          closeUpdatingToast({ message });
        }

        if (action === "Adding") {
          api
            .addLineOfBusiness(quoteData.quoteID, product.lineOfBusiness, {
              sessionUUID: quoteData.sessionUUID
            })
            .then(({ data }) => {
              const { dto, supportingData } = data;
              updateQuote({
                newData: dto,
                newSupportingData: supportingData,
                mergeData: false,
                sendToPC: false
              });
              closeUpdatingToast({ message });

              if (rateAfter)
                rateQuote()
                  .then(() => resolve({ success: true }))
                  .catch(rerateError);
              else resolve({ success: true });
            })
            .catch(({ error }) => {
              updateError({ error });
              closeUpdatingToast({ message });
            });
        } else if (action === "Removing") {
          api
            .removeLineOfBusiness(quoteData.quoteID, product.lineOfBusiness, {
              sessionUUID: quoteData.sessionUUID
            })
            .then(({ data }) => {
              const { dto, supportingData } = data;
              updateQuote({
                newData: dto,
                newSupportingData: supportingData,
                mergeData: false,
                sendToPC: false
              });
              closeUpdatingToast({ message });

              if (rateAfter)
                rateQuote()
                  .then(() => resolve({ success: true }))
                  .catch(rerateError);
              else resolve({ success: true });
            })
            .catch(({ error }) => {
              updateError({ error });
              closeUpdatingToast({ message });
            });
        }
      });
    },
    [
      closeUpdatingToast,
      quoteData?.quoteID,
      quoteData?.sessionUUID,
      rateQuote,
      showUpdatingToast,
      toastErrr,
      updateQuote
    ]
  );

  //------------------------
  //  UPDATE QUOTE SECTIONS
  //------------------------

  const updateAccountholderEmail = useCallback(
    ({ emailAddress1 }) => {
      return new Promise((resolve, reject) => {
        const accountId = quoteData?.baseData?.accountNumber;

        const message = "Updating Email Address";
        const toastId = v4();
        showUpdatingToast({ message, toastId });

        api
          .getContacts({
            accountId
          })
          .then(results => {
            const {
              accountContactPublicID,
              accountContactRoles,
              additionalAddresses,
              publicID,
              ...accountHolder
            } = results?.data?.find(a => a?.accountHolder);

            const payload = {
              ...accountHolder,
              emailAddress1
            };
            api
              .updateContact({
                accountId,
                contactId: accountContactPublicID,
                payload
              })
              .then(response => {
                updateQuote({
                  sendToPC: false,
                  mergeData: true,
                  newData: {
                    baseData: {
                      accountHolder: {
                        emailAddress1: response?.data?.emailAddress1
                      }
                    }
                  }
                });
                closeUpdatingToast({ toastId });
                resolve({
                  success: true,
                  data: response?.data,
                  error: undefined
                });
              })
              .catch(error => {
                toastErrr({
                  action: "updateAccountholderEmail",
                  description: "unable to update work comp line details",
                  displayMessage:
                    "An error occurred.  Unable to update email address.",
                  payload,
                  error
                });

                closeUpdatingToast({ toastId });
                reject({ success: false, data: null, error });
              });
          });
      });
    },
    [
      closeUpdatingToast,
      quoteData?.baseData?.accountNumber,
      showUpdatingToast,
      toastErrr,
      updateQuote
    ]
  );

  const updateBindingData = useCallback(
    ({ bindingData, updateMessage: message } = {}) => {
      return new Promise((resolve, reject) => {
        const toastId = v4();

        if (!bindingData) {
          reject({
            success: false,
            data: null,
            error: "No binding data received."
          });
          return;
        }

        const payload = {
          bindData: bindingData,
          sessionUUID: quoteData.sessionUUID
        };

        showUpdatingToast({ message, toastId });

        api
          .updateBindingData(quoteData.quoteID, payload)
          .then(results => {
            setQuoteData({ ...results.data });
            closeUpdatingToast({ toastId });
            resolve({ success: true, data: results.data, error: null });
          })
          .catch(error => {
            console.error(error);
            console.error(
              "Error updating binding data on quote " + quoteData.quoteID
            );
            closeUpdatingToast({ toastId });
            reject({ success: false, data: null, error });
          });
      });
    },
    [
      closeUpdatingToast,
      quoteData?.quoteID,
      quoteData?.sessionUUID,
      setQuoteData,
      showUpdatingToast
    ]
  );

  const updateCoveragesPromise = useCallback(
    ({ coveragesToSave, productName, coverageDetails } = {}) => {
      return new Promise((resolve, reject) => {
        if (!coveragesToSave)
          reject({
            success: false,
            data: null,
            error: "No coverage data received."
          });

        const payload = forceFailures
          ? undefined
          : {
              ...coveragesToSave,
              sessionUUID: quoteData.sessionUUID
            };

        const quoteId = forceFailures ? undefined : quoteData.quoteID;

        const messageKey = {
          added: `Adding Coverage: ${coverageDetails?.coverageName}`,
          removed: `Removing Coverage: ${coverageDetails?.coverageName}`,
          updated: `Updating Coverage: ${coverageDetails?.coverageName}`
        };
        const { action } = coverageDetails;
        const message = action ? messageKey[action] : "Updating Coverage";
        const toastId = Math.random();
        showUpdatingToast({ message, toastId });

        api
          .updateQuoteCoverages(quoteId, payload, coverageDetails)
          .then(results => {
            let _quoteData = {};

            // if results are only the coverages, then merge coverages into existing quoteData
            if (results.data[productName]) {
              _quoteData = {
                ...quoteData,
                lobData: {
                  ...quoteData.lobData,
                  [productName]: {
                    ...quoteData.lobData[productName],
                    coverages: results.data[productName]
                  }
                },
                errorsAndWarnings: {
                  validationIssues:
                    quoteData.errorsAndWarnings.validationIssues,
                  underwritingIssues:
                    results.data.errorsAndWarnings.underwritingIssues
                }
              };

              setQuoteData(_quoteData);
            }

            // if results is the entire DTO, then update it
            else if (results.data.hasOwnProperty("dto")) {
              _quoteData = { ...results.data.dto };
              setQuoteData(_quoteData);
            }

            // if results has supportingData, then update it
            if (results.data.hasOwnProperty("supportingData"))
              setSupportingData({ ...results.data.supportingData });

            resolve({ success: true, data: _quoteData, error: null });
          })
          .catch(error => {
            //TODO: need a better way to handle errors like this one accross the app.
            console.error(error);
            console.error(
              "Error updating coverages on quote " + quoteData.quoteID
            );

            toastErrr({
              action: "update coverage",
              description: "Unable to update coverage",
              payload,
              error
            });

            // we don't need to send the reject because we're logging
            // and showing the toast in this call
            // reject({ success: false, data: null, error });

            resetQuoteDataAfterFailure();
          })
          .finally(() => closeUpdatingToast({ toastId }));
      });
    },
    [
      forceFailures,
      quoteData,
      showUpdatingToast,
      setSupportingData,
      setQuoteData,
      toastErrr,
      resetQuoteDataAfterFailure,
      closeUpdatingToast
    ]
  );

  const updateCoverablesPromise = useCallback(
    ({ coverableType, coverables, action, toastMessage }) => {
      return new Promise((resolve, reject) => {
        if (!coverableType || !coverables)
          reject({
            success: false,
            data: null,
            error: "update failed, coverableType or coverables not received"
          });
        const payload = forceFailures
          ? undefined
          : { ...coverables, sessionUUID: quoteData.sessionUUID };
        const quoteId = forceFailures ? undefined : quoteData.quoteID;

        const toastId = Math.random();
        const message =
          toastMessage || toTitleCase(`${action} ${coverableType}`);
        showUpdatingToast({ message, toastId });

        api
          .updateQuoteCoverables({
            coverableType,
            quoteId,
            payload
          })
          .then(results => {
            let _quoteData = {};

            // response is only lobData, then merge it and update quoteData
            if (results?.data?.lobData) {
              _quoteData = {
                ...quoteData,
                lobData: results.data.lobData
              };
              setQuoteData({ ..._quoteData });
            }

            // if results is the entire DTO, then update it
            else if (results.data.hasOwnProperty("dto")) {
              _quoteData = { ...results.data.dto };
              setQuoteData(_quoteData);
            }

            // if response isn't eitehr of these, then something bad happened
            else {
              reject({ success: false, data: null, error: "update failed" });
            }

            // if results has supportingData, then update it
            if (results?.data?.supportingData) {
              setSupportingData({ ...results?.data?.supportingData });
            }
            resolve({ success: true, data: results?.data, error: null });
          })
          .catch(error => {
            console.error("Error:", error);
            resetQuoteDataAfterFailure();

            reject({ success: false, data: null, error });
          })
          .finally(() => closeUpdatingToast({ toastId }));
      });
    },
    [
      closeUpdatingToast,
      forceFailures,
      quoteData,
      resetQuoteDataAfterFailure,
      setQuoteData,
      setSupportingData,
      showUpdatingToast
    ]
  );

  const deleteCoverablePromise = useCallback(
    ({
      coverableType,
      coverableId,
      ca7TruckIds,
      ca7PrivatePassengerIds,
      buildingIds,
      locationId,
      employeeIds,
      toastMessage
    } = {}) => {
      return new Promise((resolve, reject) => {
        if (!coverableType) {
          reject({ success: false, data: null, error: true });
          return;
        }

        const params = {
          coverableType,
          quoteId: quoteData.quoteID,
          buildingIds,
          locationId,
          employeeIds,
          coverableId,
          ca7TruckIds,
          ca7PrivatePassengerIds
        };

        const toastId = Math.random();
        const message =
          toastMessage || toTitleCase(`Deleting ${coverableType}`);
        showUpdatingToast({ message, toastId });

        api
          .deleteQuoteCoverable(params)
          .then(results => {
            let _quoteData = {};

            // response is only lobData, then merge it and update quoteData
            if (results?.data?.lobData) {
              _quoteData = {
                ...quoteData,
                lobData: results.data.lobData
              };
              setQuoteData({ ..._quoteData });
            }

            // if results is the entire DTO, then update it
            else if (results.data.hasOwnProperty("dto")) {
              _quoteData = { ...results.data.dto };
              setQuoteData(_quoteData);
            }

            // if response isn't either of these, then something bad happened
            else {
              reject({ success: false, data: null, error: "update failed" });
              return;
            }

            // if results has supportingData, then update it
            if (results?.data?.supportingData) {
              setSupportingData({ ...results?.data?.supportingData });
            }

            // send callback after update
            resolve({ success: true, data: results?.data, error: null });
          })
          .catch(error => {
            console.error("Error:", error);
            reject({ success: false, data: null, error });
          })
          .finally(() => closeUpdatingToast({ toastId }));
      });
    },
    [
      closeUpdatingToast,
      quoteData,
      setQuoteData,
      setSupportingData,
      showUpdatingToast
    ]
  );

  const updateLineDetailsPromise = useCallback(
    ({ payload: p, line } = {}) => {
      return new Promise(resolve => {
        const payload = forceFailures ? undefined : p;
        const quoteId = forceFailures ? undefined : quoteData.quoteID;

        const toastId = Math.random();
        const message = `Updating ${productKeys[line].label} Details`;

        showUpdatingToast({ message, toastId });

        api
          .updateLineDetails(quoteId, payload, line)
          .then(results => {
            let _quoteData = {};

            // response is only lobData, then merge it and update quoteData
            if (results?.data?.lobData) {
              _quoteData = {
                ...quoteData,
                lobData: results.data.lobData
              };
              setQuoteData({ ..._quoteData });
            }

            // if results is the entire DTO, then update it
            else if (results.data.hasOwnProperty("dto")) {
              _quoteData = { ...results.data.dto };
              setQuoteData(_quoteData);
            }

            // if response isn't eitehr of these, then something bad happened
            else {
              toastErrr({
                action: "updateLineDetails",
                description: "unable to update work comp line details",
                payload
              });

              return;
            }

            // if results has supportingData, then update it
            if (results?.data?.supportingData) {
              setSupportingData({ ...results?.data?.supportingData });
            }

            // send callback after update
            resolve({
              success: true,
              data: results?.data,
              error: undefined
            });
          })

          .catch(error => {
            resetQuoteDataAfterFailure();
            console.error("Error:", error);

            toastErrr({
              action: "updateLineDetails",
              description: "unable to update work comp line details",
              payload,
              error
            });
          })
          .finally(() => closeUpdatingToast({ toastId }));
      });
    },
    [
      forceFailures,
      quoteData,
      showUpdatingToast,
      setQuoteData,
      toastErrr,
      setSupportingData,
      resetQuoteDataAfterFailure,
      closeUpdatingToast
    ]
  );

  const updateScheduledItem = useCallback(
    ({
      apiPayload,
      selectedItem,
      newFieldsMetadata,
      letCallerCloseToast,
      toastMessage
    }) => {
      return new Promise((resolve, reject) => {
        const toastId = toastMessage || Math.random();
        showUpdatingToast({
          message: toastMessage || "Updating Scheduled Item",
          toastId
        });

        // make the update call
        api
          .updateScheduledItem({
            ...apiPayload,
            fieldsMetadata: newFieldsMetadata,
            coverages: [],
            scheduleItemId: selectedItem.data.scheduleItemFixedId,
            coverableFixedId:
              selectedItem.field.clauseScheduleItems.coverableFixedId
          })
          .then(results => {
            if (!!results?.data.fieldsMetadata) {
              // after the api call, we need to update the local quote data
              const newData = { ...quoteData };

              const coverageArray = SiUtils.findCoverageToUpdate({
                field: selectedItem.field,
                quoteData: newData
              });

              if (!coverageArray)
                reject({
                  success: false,
                  data: null,
                  error: "no coverageArray"
                });

              const coverageToUpdate = coverageArray.find(
                c => c.publicID === selectedItem.field.publicID
              );
              const scheduleItemToUpdateIndex =
                coverageToUpdate.clauseScheduleItems.scheduleItems.findIndex(
                  i =>
                    i.scheduleItemFixedId ===
                    selectedItem.data.scheduleItemFixedId
                );
              coverageToUpdate.clauseScheduleItems.scheduleItems[
                scheduleItemToUpdateIndex
              ] = results.data;

              // update the local quote with the quote data and the coverage updated on it
              setQuoteData({ ...newData });
              resolve({
                success: true,
                data: results?.data,
                error: undefined
              });
            } else {
              reject({
                success: false,
                data: null,
                error: "no results.data.fieldsMetadata"
              });
            }
          })
          .catch(error => {
            reject({
              success: false,
              data: null,
              error: error?.response?.data || error
            });
          })
          .finally(() => {
            if (!letCallerCloseToast) closeUpdatingToast({ toastId });
          });
      });
    },
    [closeUpdatingToast, quoteData, setQuoteData, showUpdatingToast]
  );

  const get = useMemo(
    () => ({
      /**
       *
       * @param {data} - a custom object of quoteData
       * @param {lob} - request locations for a single line of business
       * @returns an array of all locaitons on the quote
       */
      locations: props => {
        const { data, lob } = props || {};
        const _quoteData = data || quoteData;

        // allow the method to recieve a specific set of quoteData to use
        // ... instead of the quoteData currently in state
        // ... example: LocationFormStep1 passes in data
        const rawLocations = {
          bp7BusinessOwners:
            _quoteData?.lobData?.bp7BusinessOwners?.coverables?.locations || [],
          ca7CommAuto:
            _quoteData?.lobData?.ca7CommAuto?.coverables?.locations || [],
          wcmWorkersComp:
            _quoteData?.lobData?.wcmWorkersComp?.coverables?.locations || []
        };

        if (lob) return rawLocations[lob];

        const loc = (l, type) => {
          return {
            ...l,
            id: l?.id || l?.fixedId || l?.fixedID,
            linesOfBusiness: [type]
          };
        };

        const all = rawLocations.bp7BusinessOwners.map(l => ({
          ...loc(l, "bp7BusinessOwners"),
          disableDelete: l.isPrimary
        }));

        // combine locations by `fixedID`

        rawLocations.ca7CommAuto.forEach(l => {
          const i = all.findIndex(i => i.fixedID === l.fixedID);
          if (i >= 0) {
            // exists... merge location data
            const merged = { ...l, ...all[i] };

            // only mark a location as auto if location not in supportingData.autoLocationFixedIDs (HNO)
            if (
              !!(supportingData?.autoLocationFixedIDs || []).includes(l.fixedID)
            )
              merged.linesOfBusiness = [
                ...all[i].linesOfBusiness,
                "ca7CommAuto"
              ];

            all.splice(i, 1, merged);
          } else {
            // add location to all
            all.push(loc(l, "ca7CommAuto"));
          }
        });

        rawLocations.wcmWorkersComp.forEach(l => {
          const i = all.findIndex(i => i.fixedID === l.fixedID);
          if (i >= 0) {
            // exists... merge location data
            all.splice(i, 1, {
              ...l,
              ...all[i],
              linesOfBusiness: [...all[i].linesOfBusiness, "wcmWorkersComp"]
            });
          } else {
            // add location to all
            all.push(loc(l, "wcmWorkersComp"));
          }
        });

        return all;
      },
      /**
       *
       * @param {object} which - returns a single location that matches the first prop
       * @returns
       *
       * usage: get.location({wcmLocationFixedID: someId})
       * usage: get.location({fixedID: 12345})
       */
      location: (which, lob) => {
        if (typeof which === "object") {
          const prop = Object.keys(which)[0];
          return get.locations({ lob }).find(l => l[prop] === which[prop]);
        }
        return null;
      },
      employees: props => {
        const { data, locationId } = props || {};
        const _quoteData = data || quoteData;

        // if locationId is included, only include employees at the location, else return all employees
        const locations = get.locations({ data: _quoteData }).filter(l => {
          if (locationId) return l.wcmLocationFixedID === locationId;
          return true;
        });

        const _employees = [];
        locations.forEach(l =>
          _employees.push(
            ...(l.coveredEmployees || []).map(e => ({
              ...e,
              id: e.fixedId,
              locationId: l.wcmLocationFixedID,
              className:
                _quoteData?.errorsAndWarnings?.validationIssues?.issues?.some(
                  i => i?.relatedEntity?.fixedId === e.fixedId
                )
                  ? "oq__coverable__tableRowWithError"
                  : ""
            }))
          )
        );
        return _employees;
        // .map(e => {
        //   return {
        //     ...e,
        //     id: e.fixedId
        //   };
        // });
      },
      // employeesByLocaiton: (data,locationId) =>{
      //   const _quoteData = data ? { ...data } : quoteData;
      //   get.employees(data).filter(e=>e.locationId===locationId)

      // },
      employee: ({ fixedId }) => {
        return get.employees().find(v => v.fixedId === fixedId);
      }
    }),
    [quoteData, supportingData?.autoLocationFixedIDs]
  );

  return useMemo(
    () => ({
      quoteData,
      supportingData,
      createQuote,
      updateQuote,
      patchQuote,
      patchQuotePromise,
      updateCoveragesPromise,
      updatePriorLosses,
      changeLineOfBusiness,
      quoteIsUpdating,
      setQuoteIsUpdating,
      showUpdatingToast,
      closeUpdatingToast,
      clearLocalQuote,
      getQuotePart,
      step,
      setStep,
      updateAccountholderEmail,
      updateCoverablesPromise,
      deleteCoverablePromise,
      updateLineDetailsPromise,
      updateScheduledItem,
      updateSupportingDataPromise,
      supportingDataIsUpdating,
      navigateToStep,
      pageValidation,
      updateBindingData,
      getQuote,
      quoteRecovery,
      refreshQuoteFromPC,
      rateQuote,
      issueQuote,
      validateCoverablesPromise,
      loggingData,
      get,
      forceFailures,
      setForceFailures,
      effectiveDateOverride,
      setEffectiveDateOverride,
      isNewQA,
      getFilteredUnderwriterIssues,
      hasBlockingUwIssues,
      stpException,
      toastErrr,
      getVendorQuote
    }),
    [
      quoteData,
      supportingData,
      createQuote,
      updateQuote,
      patchQuote,
      patchQuotePromise,
      updateCoveragesPromise,
      updatePriorLosses,
      changeLineOfBusiness,
      quoteIsUpdating,
      setQuoteIsUpdating,
      showUpdatingToast,
      closeUpdatingToast,
      clearLocalQuote,
      getQuotePart,
      step,
      setStep,
      updateAccountholderEmail,
      updateCoverablesPromise,
      deleteCoverablePromise,
      updateLineDetailsPromise,
      updateScheduledItem,
      updateSupportingDataPromise,
      supportingDataIsUpdating,
      navigateToStep,
      pageValidation,
      updateBindingData,
      getQuote,
      quoteRecovery,
      refreshQuoteFromPC,
      rateQuote,
      issueQuote,
      validateCoverablesPromise,
      loggingData,
      get,
      forceFailures,
      setForceFailures,
      effectiveDateOverride,
      setEffectiveDateOverride,
      isNewQA,
      getFilteredUnderwriterIssues,
      hasBlockingUwIssues,
      stpException,
      toastErrr,
      getVendorQuote
    ]
  );
};
