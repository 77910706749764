import React, { useReducer, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  useForm,
  Button,
  FlexRow,
  formatDate
} from "@ufginsurance/ui-kit";
import Step1ProjectInfo from "./Step1-ProjectInfo";
import ReviewConsent from "./Step2-ReviewConsent";
import Confirmation from "./Step3-Confirmation";
import CoSSideBar from "../sidebar/CoSSideBar";
import { createConsentOfSuretyPayload } from "../../shared/utils";
import { postConsentOfSurety } from "../../services";
import { logger } from "../../../loggers";
import { useSuretyContext } from "../../surety-context/SuretyContext";

const CoSFormWrapper = ({ history, location: { search }, step, setStep }) => {
  const query = new URLSearchParams(search);
  const bond_number = query.get("bond_number");
  const {
    cosDevToolsForm: { values: cosDevToolsValues }
  } = useSuretyContext();

  const [underwriter, setUnderwriter] = useState(null);
  const [cstEmail, setCstEmail] = useState(null);

  const [submission, updateSubmission] = useReducer(
    (submission, updatedSubmission) => ({
      ...submission,
      ...updatedSubmission
    }),
    { loading: false, success: false, error: false }
  );

  const initialValues = {
    account_name: "",
    account_number: "",
    accountBondSearch: bond_number || "",
    accountBondSearchInput: bond_number || "",
    address_line: "",
    all_bills_paid: "",
    architect_project_num: "",
    bond_number: bond_number || "",
    bond_status: "",
    bond_type: "",
    city: "",
    claims_against_contractor: "",
    comments: "",
    completion_date: "",
    consent_type: "",
    contact_person: "",
    contract_dated: "",
    contract_for: "",
    final_contract_price: "",
    isValidForConsentOfSurety: true,
    obligee_address_line: "",
    obligee_city: "",
    obligee_name: "",
    obligee_state: "",
    obligee_zip: "",
    project_description: "",
    receivedQuery: query.get("bond_number"),
    recentConsentOfSurety: false,
    retainage_change_request: "",
    saved_bond_options: [],
    sign_seal_date: formatDate(new Date(), "YYYY-MM-DD"),
    state: "",
    total_claim_value: "",
    zip: ""
  };

  const consentOfSuretyTypes = [
    {
      value: "F",
      label: "Consent of Surety to Final Payment",
      form: "CONT0311"
    },
    {
      value: "R",
      label:
        "Consent of Surety to Reduction In or Partial Release of Retainage",
      form: "CONT0312"
    }
  ];

  const handleSubmit = ({ values }) => {
    updateSubmission({ loading: true });
    const payload = createConsentOfSuretyPayload(values, consentOfSuretyTypes);
    postConsentOfSurety(payload)
      .then(res => {
        if (res.status === 200) {
          updateSubmission({ success: true, loading: false });
        }
      })
      .catch(err => {
        logger.error({
          description: err.toString(),
          fatal: false
        });
        updateSubmission({ error: true, loading: false });
      })
      .finally(() => {
        setStep(3);
      });
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleSubmit
  });

  const { values, updateForm, invalidFields } = form;

  const handleReset = () => {
    if (query.has("bond_number")) {
      initialValues.accountBondSearch =
        initialValues.accountBondSearchInput =
        initialValues.bond_number =
          "";
    }
    const initialErrors = {
      consent_type: [],
      sign_seal_date: [],
      final_contract_price: [],
      change_effective_date: [],
      project_description: [],
      retainage_change_request: [],
      obligee_name: [],
      obligee_address_line: [],
      obligee_building_number: [],
      obligee_street_name: [],
      obligee_zip: [],
      obligee_city: [],
      obligee_state: [],
      all_bills_paid: [],
      contract_dated: [],
      contract_for: [],
      claims_against_contractor: [],
      total_claim_value: [],
      completion_date: [],
      architect_project_num: []
    };

    updateForm({
      values: initialValues,
      errors: initialErrors
    });
  };

  return (
    <Form context={form}>
      <div className="cos__layout__left-sidebar__container">
        <div className="co__layout__left-sidebar">
          <CoSSideBar
            step={step}
            history={history}
            form={form}
            underwriter={underwriter}
            setUnderwriter={setUnderwriter}
            setCstEmail={setCstEmail}
          />
        </div>
        <div className="cos__content-body">
          {step === 1 && (
            <Step1ProjectInfo
              form={{ ...form, handleReset }}
              consentOfSuretyTypes={consentOfSuretyTypes}
            />
          )}
          {step === 2 && (
            <ReviewConsent
              form={form}
              consentOfSuretyTypes={consentOfSuretyTypes}
            />
          )}
          {step === 3 && (
            <Confirmation
              history={history}
              submission={submission}
              underwriter={underwriter}
              cstEmail={cstEmail}
            />
          )}
          {step === 1 && (
            <FlexRow align="right">
              <Button
                variant="primary"
                onClick={() => setStep(2)}
                disabled={
                  (!values.isValidForConsentOfSurety ||
                    invalidFields.length !== 0) &&
                  !cosDevToolsValues?.submissionOverride
                }
              >
                Continue to Summary
              </Button>
            </FlexRow>
          )}
          {step === 2 && (
            <FlexRow align="left">
              <div className="">
                <Button isLink onClick={() => setStep(1)}>
                  Previous
                </Button>
              </div>
              <div className="align-right">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    (!values.contact_person || submission?.loading) &&
                    !cosDevToolsValues?.submissionOverride
                  }
                  spinner={submission?.loading}
                >
                  Submit Request
                </Button>
              </div>
            </FlexRow>
          )}
        </div>
      </div>
    </Form>
  );
};

export default withRouter(CoSFormWrapper);
