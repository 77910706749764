import { BP7EndorsementType_UFG } from "./coverageOverrides/BP7EndorsementType_UFG";
import { WCMFactor14 } from "./coverageOverrides/WCMFactor14";
import { CA7LimitText } from "./coverageOverrides/CA7VehicleLiab";
import { CA7CombinedSingleLimitText1 } from "./coverageOverrides/CA7CombinedSingleLimitText1";
import { BP7BusinessIncome } from "./coverageOverrides/BP7BusinessIncome";

export const coverageTermOverrides = {
  BP7BusinessIncome,
  BP7EndorsementType_UFG,
  WCMFactor14,
  CA7LimitText,
  CA7CombinedSingleLimitText1
};

/**
 * imports an object based on the panel "key"
 * object example:
  {
    showBefore: (<>Some React Element</>) // optional
    showAfter: (<>Some React Element</>) // optional
  }
 */
export const coveragePanelJsx = {
  BP7BusinessIncome
};
